import React, { useState, useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useAppContext } from 'context';
import Container from 'components/Layout/Container';
import removeEmptyObjectKeys from 'utils/removeEmptyObjectKeys';
import TranslationKey, { TranslationKeyString } from 'context/TranslationKey';
import RevealElement from 'components/Layout/RevealElement';
import SelectDropdown from 'components/common/SelectDropdown';
import { GeneralModuleBlocks } from 'components/Modules/general/GeneralModuleBlocks';
import { HeaderModuleBlocks } from 'components/Modules/header/HeaderModuleBlocks';
import Athlete from 'components/common/Athlete';
import Pagination from 'components/common/Pagination';
import styles from './Athletes.module.scss';
import axios from 'axios';

const Athletes = ({ template, headerGroup, generalModulesGroup }) => {
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState({
        page: 1,
        sport: '',
        nationality: '',
        tp: null,
        search: '',
    });
    const [sportTypes, setSportTypes] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [athletes, setAthletes] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const pageSize = useMemo(() => 12, []);

    const isPartner = useMemo(() => template === 'Partners Template', [template]);

    const router = useRouter();
    const { language, countryCode } = useAppContext();

    const changeLocationParams = useCallback(
        (query, params) => {
            const paramsString = new URLSearchParams(
                removeEmptyObjectKeys({
                    ...query,
                    ...params,
                })
            ).toString();
            // push to router without page refresh
            if (location) {
                router.push(`${location.pathname}/?${paramsString}`, undefined, { shallow: true });
            }
        },
        [router]
    );

    useEffect(() => {
        setQuery({
            page: Number(router?.query?.page || 1),
            sport: router?.query?.sport?.toString() || '',
            nationality: router?.query?.nationality?.toString() || '',
            tp: router?.query?.tp?.toString() || null,
            search: router?.query?.search?.toString() || '',
        });
    }, [router.query, setQuery]);

    const onSportChange = useCallback(
        value => {
            const firstValue = value && value[0];
            changeLocationParams(query, { sport: firstValue?.value, page: 1 });
        },
        [query, changeLocationParams]
    );

    const onNationalityChange = useCallback(
        value => {
            const firstValue = value && value[0];
            changeLocationParams(query, { nationality: firstValue?.value, page: 1 });
        },
        [query, changeLocationParams]
    );

    const onSearchChange = useCallback(
        event => {
            changeLocationParams(query, { search: event?.target?.value ?? '' });
        },
        [query, changeLocationParams]
    );

    const searchSubmitHandler = useCallback(
        e => {
            e?.preventDefault?.();
            const search = e.target.search?.value ?? '';
            changeLocationParams(query, { search });
        },
        [changeLocationParams, query]
    );

    useEffect(() => {
        axios
            .get('/api/sportTypes', {
                params: { language },
            })
            .then(results => {
                const filtered = results?.data?.list?.map(item => ({
                    id: item?.id,
                    value: item?.slug,
                    label: item?.name,
                }));
                setSportTypes(filtered);
            });
        axios
            .get('/api/nationalities', {
                params: { language },
            })
            .then(results => {
                const filtered = results?.data?.list?.map(item => ({
                    value: item?.countryCode,
                    label: item?.country,
                }));
                setNationalities(filtered);
            });
    }, [language]);

    const filteredAthletes = useMemo(
        () =>
            athletes?.map(item => ({
                id: item?.id,
                name: item?.options?.title,
                subtitle: item?.athletePostType?.subtitle || null,
                nationality: {
                    code: item?.athletePostType?.nationality[0],
                    name: item?.athletePostType?.nationality[1],
                },
                sport: {
                    name: item?.sportTypes?.nodes[0]?.name,
                    slug: item?.sportTypes?.nodes[0]?.slug,
                },
                photos: {
                    vertical: {
                        sourceUrl: !!item?.athletePostType?.partner
                            ? item?.athletePostType?.partnersVertical ||
                              item?.athletePostType?.photoVertical ||
                              ''
                            : item?.athletePostType?.photoVertical || '',
                        altText: item?.options?.title || '',
                        mediaDetails: {
                            sizes: [],
                        },
                    },
                    horizontal: {
                        sourceUrl: !!item?.athletePostType?.partner
                            ? item?.athletePostType?.partnersHorizontal ||
                              item?.athletePostType?.photoHorizontal ||
                              ''
                            : item?.athletePostType?.photoHorizontal || '',
                        altText: item?.options?.title || '',
                        mediaDetails: {
                            sizes: [],
                        },
                    },
                },
                url:
                    item?.options?.countries?.find(
                        country =>
                            country?.countryCode?.toLowerCase() === countryCode?.toLowerCase()
                    )?.url || '#',
            })) || [],
        [athletes, countryCode]
    );

    useEffect(() => {
        setLoading(true);
        axios
            .get('/api/athletes', {
                params: {
                    language,
                    countryCode,
                    offset: (Number(query?.page) - 1) * pageSize,
                    limit: pageSize,
                    sport: query?.sport || '',
                    nationality: query?.nationality || '',
                    search: query?.search || '',
                    partner: isPartner ? '1' : '0',
                },
            })
            .then(results => {
                setAthletes([...results?.data?.data]);
                setTotalResults(Number(results?.data?.total || 0));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [language, countryCode, query, pageSize, isPartner]);

    return (
        <>
            {headerGroup?.headerModules?.map((block, index) => (
                <HeaderModuleBlocks key={index} layoutData={block as unknown} />
            ))}

            <section
                className={cx(styles.main, {
                    [`${styles.mainNoHeader}`]: !headerGroup?.headerModules,
                })}
            >
                <Container>
                    <header className={styles.header}>
                        <div className={styles.headerCol}>
                            <RevealElement>
                                <h2 className="u-a4">
                                    <TranslationKey
                                        name={
                                            isPartner
                                                ? 'discoverAllPartners'
                                                : 'discoverAllAthletes'
                                        }
                                    />
                                </h2>
                            </RevealElement>
                        </div>
                        <div className={cx(styles.headerCol, styles.headerColFullRow)}>
                            <div className={styles.dropdownCol}>
                                <RevealElement>
                                    <form onSubmit={searchSubmitHandler}>
                                        <input
                                            name="search"
                                            className={cx(styles.textInput, 'u-form__input')}
                                            type="text"
                                            placeholder={TranslationKeyString('name')}
                                            defaultValue={router?.query?.search}
                                            onBlur={onSearchChange}
                                        />
                                    </form>
                                </RevealElement>
                            </div>
                            <div className={styles.dropdownCol}>
                                <RevealElement>
                                    <div className={styles.dropdown}>
                                        <SelectDropdown
                                            onChange={onSportChange}
                                            options={sportTypes}
                                            selectedValue={query?.sport}
                                            disabled={sportTypes?.length === 0}
                                            placeholder={TranslationKeyString('typeToFilter')}
                                            prefix={TranslationKeyString('sport')}
                                            allOptionLabel={TranslationKeyString('all')}
                                        />
                                    </div>
                                </RevealElement>
                            </div>
                            <div className={styles.dropdownCol}>
                                <RevealElement>
                                    <div className={styles.dropdown}>
                                        <SelectDropdown
                                            onChange={onNationalityChange}
                                            options={nationalities}
                                            selectedValue={query?.nationality}
                                            disabled={nationalities?.length === 0}
                                            placeholder={TranslationKeyString('typeToFilter')}
                                            prefix={TranslationKeyString('nationality')}
                                            allOptionLabel={TranslationKeyString('all')}
                                        />
                                    </div>
                                </RevealElement>
                            </div>
                        </div>
                    </header>

                    {filteredAthletes?.length > 0 ? (
                        <div
                            className={cx(styles.container, {
                                [styles.isLoading]: loading,
                            })}
                        >
                            <div className={styles.row}>
                                {filteredAthletes?.map(athlete => (
                                    <div key={athlete.id} className={styles.col}>
                                        <RevealElement>
                                            <Athlete
                                                addTp
                                                title={athlete?.name}
                                                subtitle={athlete?.subtitle}
                                                sport={athlete?.sport?.name}
                                                photos={athlete?.photos}
                                                link={athlete?.url}
                                                country={athlete?.nationality}
                                                variation="big"
                                                align="center"
                                                lazy={false}
                                            />
                                        </RevealElement>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p className={cx(styles.emptyTitle, 'u-a3')}>
                            <TranslationKey name="noResults" />
                        </p>
                    )}

                    {totalResults > pageSize && (
                        <RevealElement>
                            <Pagination
                                disabled={loading}
                                current={query?.page || 1}
                                pageSize={pageSize}
                                total={totalResults}
                                onPageChange={pageNumber =>
                                    changeLocationParams(query, { page: pageNumber })
                                }
                            />
                        </RevealElement>
                    )}
                </Container>
            </section>

            {generalModulesGroup?.modules?.map((block, index) => (
                <GeneralModuleBlocks key={index} layoutData={block as unknown} />
            ))}
        </>
    );
};

Athletes.defaultProps = {
    headerGroup: {},
    generalModulesGroup: {},
};

Athletes.propTypes = {
    headerGroup: PropTypes.oneOfType([PropTypes.object]),
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
};

export default Athletes;
