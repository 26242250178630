import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useAppContext } from 'context';
import removeEmptyObjectKeys from 'utils/removeEmptyObjectKeys';
import TranslationKey, { TranslationKeyString } from 'context/TranslationKey';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import SelectDropdown from 'components/common/SelectDropdown';
import Pagination from 'components/common/Pagination';
import { HeaderModuleBlocks } from 'components/Modules/header/HeaderModuleBlocks';
import { GeneralModuleBlocks } from 'components/Modules/general/GeneralModuleBlocks';
import MagazineArticles from 'components/common/MagazineArticles';
import { generateCategories } from 'components/common/ArticleItem';
import styles from './Magazines.module.scss';

const MagazinesArchive = ({ headerGroup, generalModulesGroup }) => {
    const $topSection = useRef(null);

    const [query, setQuery] = useState({ page: 1, category: '', sort: 'DESC', tp: null });
    const [allCategories, setAllCategories] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);

    const router = useRouter();
    const { language, countryCode } = useAppContext();

    const pageSize = useMemo(() => 17, []);

    const changeLocationParams = useCallback(
        (query, params) => {
            const paramsString = new URLSearchParams(
                removeEmptyObjectKeys({
                    ...query,
                    ...params,
                })
            ).toString();
            // push to router without page refresh
            if (location) {
                router.push(`${location.pathname}/?${paramsString}`, undefined, { shallow: true });
            }
        },
        [router]
    );

    const filteredArticles = useMemo(
        () =>
            articles?.reduce(
                (acc, article) => [
                    ...acc,
                    {
                        id: article.id,
                        date: article.date,
                        title:
                            article?.magazineSingleContent?.titleoverride || article.options.title,
                        category: generateCategories(article?.magazineCategories?.nodes),
                        image: {
                            sourceUrl:
                                article?.magazineSingleContent?.headlineImageCrop ||
                                article?.magazineSingleContent?.headlineImage?.sourceUrl ||
                                '#',
                            altText:
                                article?.magazineSingleContent?.headlineImage?.altText ||
                                article?.magazineSingleContent?.titleoverride ||
                                article.options.title,
                        },
                        url:
                            article?.options?.countries?.find(
                                country =>
                                    country?.countryCode?.toLowerCase() ===
                                    countryCode?.toLowerCase()
                            )?.url || '#',
                    },
                ],
                []
            ),
        [articles, countryCode]
    );

    const selectedCategories = useMemo(
        () =>
            router?.query?.category
                ?.toString()
                ?.split(',')
                ?.reduce((acc, item) => {
                    if (item.trim()) return [...acc, item];
                    return [...acc];
                }, []),
        [router.query]
    );

    useEffect(() => {
        setQuery({
            page: Number(router?.query?.page || 1),
            category: router?.query?.category?.toString() || '',
            sort: router?.query?.sort?.toString() || 'DESC',
            tp: router?.query?.tp?.toString() || null,
        });
    }, [router.query, setQuery]);

    useEffect(() => {
        axios
            .get('/api/magazineCategories', {
                params: { language },
            })
            .then(results => {
                const data = results?.data?.list?.reduce((acc, item) => {
                    if (item?.id && item?.slug && item?.name) {
                        return [...acc, { id: item.id, value: item.slug, label: item.name }];
                    }
                    return [...acc];
                }, []);
                setAllCategories(data);
            });
    }, [language]);

    useEffect(() => {
        setLoading(true);
        axios
            .get('/api/magazines', {
                params: {
                    language,
                    countryCode,
                    orderBy: query?.sort || 'DESC',
                    offset: (Number(query?.page) - 1) * pageSize,
                    limit: pageSize,
                    categories: query?.category,
                },
            })
            .then(results => {
                setArticles([...results?.data?.data]);
                setTotalResults(Number(results?.data?.total || 0));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [language, countryCode, query, pageSize]);

    const onSortChange = useCallback(
        (ev, value) => {
            ev.preventDefault();
            changeLocationParams(query, { sort: value });
        },
        [query, changeLocationParams]
    );

    const onCategoryChange = useCallback(
        values => {
            const selectedQuery = values
                .reduce((acc, item) => {
                    if (item?.value) return [...acc, item.value];
                    return [...acc];
                }, [])
                .join(',');
            changeLocationParams(query, { category: selectedQuery, page: 1 });
        },
        [query, changeLocationParams]
    );

    return (
        <>
            {headerGroup?.headerModules?.map((block, index) => (
                <HeaderModuleBlocks key={index} layoutData={block as unknown} />
            ))}

            <section
                ref={$topSection}
                className={cx(styles.main, 'js-no-scroll-top', {
                    [`${styles.mainNoHeader}`]: !headerGroup?.headerModules,
                })}
            >
                <Container>
                    <header className={styles.header}>
                        <div className={styles.headerCol}>
                            <RevealElement>
                                <h2 className="u-a4">
                                    <TranslationKey name="ourLatestArticles" />
                                </h2>
                            </RevealElement>
                        </div>
                        <div className={styles.headerCol}>
                            <div className={styles.listsort}>
                                <RevealElement>
                                    <ul
                                        className={cx('u-b3', styles.listinline, {
                                            [styles.isLoading]: loading,
                                        })}
                                    >
                                        <li>
                                            <TranslationKey name="sortBy" />
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                className={cx(styles.btnsort, {
                                                    [`${styles.btnactive}`]: query?.sort === 'DESC',
                                                })}
                                                onClick={ev => onSortChange(ev, 'DESC')}
                                            >
                                                <TranslationKey name="latest" />
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                className={cx(styles.btnsort, {
                                                    [`${styles.btnactive}`]: query?.sort === 'ASC',
                                                })}
                                                onClick={ev => onSortChange(ev, 'ASC')}
                                            >
                                                <TranslationKey name="oldest" />
                                            </button>
                                        </li>
                                    </ul>
                                </RevealElement>
                            </div>
                            <div className={styles.dropdown}>
                                <RevealElement>
                                    <SelectDropdown
                                        multi
                                        showAllInList={false}
                                        onChange={onCategoryChange}
                                        options={allCategories}
                                        disabled={allCategories?.length === 0}
                                        selectedValue={selectedCategories}
                                        label={
                                            selectedCategories?.length > 1
                                                ? `${TranslationKeyString('category')}: 1+`
                                                : null
                                        }
                                        placeholder={TranslationKeyString('typeToFilter')}
                                        prefix={TranslationKeyString('category')}
                                        allOptionLabel={TranslationKeyString('all')}
                                    />
                                </RevealElement>
                            </div>
                        </div>
                    </header>
                    <MagazineArticles loading={loading} articles={filteredArticles} />
                    {totalResults > pageSize && (
                        <RevealElement>
                            <Pagination
                                disabled={loading}
                                current={query?.page || 1}
                                pageSize={pageSize}
                                total={totalResults}
                                onPageChange={pageNumber =>
                                    changeLocationParams(query, { page: pageNumber })
                                }
                            />
                        </RevealElement>
                    )}
                </Container>
            </section>

            {generalModulesGroup?.modules?.map((block, index) => (
                <GeneralModuleBlocks key={index} layoutData={block as unknown} />
            ))}
        </>
    );
};

MagazinesArchive.defaultProps = {
    headerGroup: {},
    generalModulesGroup: {},
};

MagazinesArchive.propTypes = {
    headerGroup: PropTypes.oneOfType([PropTypes.object]),
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
};

export default MagazinesArchive;
