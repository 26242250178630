import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useAppContext } from 'context';
import Container from 'components/Layout/Container';
import removeEmptyObjectKeys from 'utils/removeEmptyObjectKeys';
import TranslationKey, { TranslationKeyString } from 'context/TranslationKey';
import RevealElement from 'components/Layout/RevealElement';
import SelectDropdown from 'components/common/SelectDropdown';
import { GeneralModuleBlocks } from 'components/Modules/general/GeneralModuleBlocks';
import { HeaderModuleBlocks } from 'components/Modules/header/HeaderModuleBlocks';
import Pagination from 'components/common/Pagination';
import styles from './Testimonials.module.scss';
import Testimonial from 'components/common/Testimonial';
import TestimonialModal from 'components/common/TestimonialModal';
import axios from 'axios';

const Testimonials = ({ headerGroup, generalModulesGroup }) => {
    const [loading, setLoading] = useState(true);

    const [query, setQuery] = useState({
        page: 1,
        countryCode: '',
        product: '',
        gender: '',
        testimonialId: undefined,
    });

    const [testimonials, setTestimonials] = useState([]);
    const [countries, setCountries] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [activeTestimonial, setActiveTestimonial] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const fetchedSharedTestimonial = useRef(false);

    const pageSize = useMemo(() => 12, []);

    const router = useRouter();
    const { language, countryCode } = useAppContext();

    const changeLocationParams = useCallback(
        (query, params) => {
            const paramsString = new URLSearchParams(
                removeEmptyObjectKeys({
                    ...query,
                    ...params,
                })
            ).toString();
            // push to router without page refresh
            if (location) {
                router.push(`${location.pathname}/?${paramsString}`, undefined, { shallow: true });
            }
        },
        [router]
    );

    useEffect(() => {
        setQuery({
            page: Number(router?.query?.page || 1),
            countryCode: router?.query?.countryCode?.toString() || '',
            product: router?.query?.product?.toString() || '',
            gender: router?.query?.gender?.toString() || null,
            testimonialId: router?.query?.testimonialId || null,
        });
    }, [router.query, setQuery]);

    const onCountryChange = useCallback(
        value => {
            const firstValue = value && value[0];
            changeLocationParams(query, {
                countryCode: firstValue?.value,
                page: 1,
                testimonialId: query.testimonialId,
            });
        },
        [query, changeLocationParams]
    );

    const onProductChange = useCallback(
        value => {
            const firstValue = value && value[0];
            changeLocationParams(query, {
                product: firstValue?.value,
                page: 1,
                testimonialId: query.testimonialId,
            });
        },
        [query, changeLocationParams]
    );

    const onGenderChange = useCallback(
        value => {
            const firstValue = value && value[0];
            changeLocationParams(query, {
                gender: firstValue?.value,
                page: 1,
                testimonialId: query.testimonialId,
            });
        },
        [query, changeLocationParams]
    );

    const openModal = useCallback((ev, testimonial) => {
        if (ev) {
            ev.preventDefault();
            setActiveTestimonial(testimonial);
            setShowModal(true);
        }
    }, []);

    const closeModal = useCallback(
        ev => {
            if (ev) {
                ev.preventDefault();
                setShowModal(false);
                setActiveTestimonial(null);
                setQuery(existingQuery => {
                    //remove testimonial id from url/query
                    const routerQuery = router.query;
                    delete routerQuery.testimonialId;
                    router.push(
                        {
                            pathname: router.pathname,
                            query: { ...routerQuery },
                        },
                        undefined,
                        { shallow: true }
                    );

                    return { ...existingQuery, testimonialId: null };
                });
            }
        },
        [router]
    );

    const escKey = useCallback(
        ev => {
            if (ev.key === 13 || ev.key === 'Escape') {
                closeModal(ev);
            }
        },
        [closeModal]
    );

    useEffect(() => {
        document.addEventListener('keydown', escKey, false);
        return () => {
            document.removeEventListener('keydown', escKey, false);
        };
    }, [escKey]);

    useEffect(() => {
        axios
            .get('/api/testimonials/countries', {
                params: { language },
            })
            .then(results => {
                const filtered = results?.data?.list?.map(item => ({
                    value: item?.countryCode,
                    label: item?.country,
                }));
                setCountries(filtered);
            });
        axios
            .get('/api/testimonials/products', {
                params: { language },
            })
            .then(results => {
                const filtered = results?.data?.list?.map(item => ({
                    value: item?.databaseId,
                    label: item?.name,
                }));
                setProducts(filtered);
            });
    }, [language]);

    const testimonialMapper = useCallback(
        item => ({
            id: item?.id,
            name: item?.title,
            quote: item?.testimonialPostType?.text || '',
            video: item?.testimonialPostType?.video || '',
            country: {
                code: item?.testimonialPostType?.country?.[0] ?? '',
                name: item?.testimonialPostType?.country?.[1] ?? '',
            },
            photos: {
                vertical: {
                    sourceUrl: item?.testimonialPostType?.photoVertical || '',
                    altText: item?.title || '',
                },
                horizontal: {
                    sourceUrl: item?.testimonialPostType?.photoHorizontal || '',
                    altText: item?.title || '',
                },
            },
            product: {
                name: item?.testimonialPostType?.product?.[0]?.options?.title || '',
                sourceUrl:
                    item?.testimonialPostType?.product?.[0]?.productGroup?.image?.sourceUrl || '',
                url:
                    item?.testimonialPostType?.product?.[0]?.options?.countries?.find(
                        country =>
                            country?.language?.toLowerCase() === language?.toLowerCase() &&
                            country?.countryCode?.toLowerCase() === countryCode?.toLowerCase()
                    )?.url || null,
            },
        }),
        [countryCode, language]
    );

    const filteredTestimonials = useMemo(
        () => testimonials?.map(testimonialMapper) || [],
        [testimonials, testimonialMapper]
    );

    useEffect(() => {
        setLoading(true);
        axios
            .get('/api/testimonials', {
                params: {
                    language,
                    countryCode: query?.countryCode || '',
                    gender: query?.gender || '',
                    product: query?.product || '',
                    offset: (Number(query?.page) - 1) * pageSize,
                    limit: pageSize,
                },
            })
            .then(results => {
                setTestimonials([...results?.data?.data]);
                setTotalResults(Number(results?.data?.total || 0));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [language, query, pageSize]);

    useEffect(() => {
        // only fetch shared testimonial once
        if (fetchedSharedTestimonial.current) return;
        if (router?.query?.testimonialId && filteredTestimonials.length) {
            fetchedSharedTestimonial.current = true;
            const targetTestimonialId = decodeURIComponent(router.query.testimonialId as string);

            // try finding testimonial in currently loaded dataset
            const targetTestimonial = filteredTestimonials.find(
                testimonial => testimonial.id === targetTestimonialId
            );
            if (targetTestimonial) {
                setActiveTestimonial(targetTestimonial);
                setShowModal(true);
            } else {
                // testimonial not found in current set, fetch it separately
                axios
                    .get('/api/testimonials/testimonial', {
                        params: {
                            id: targetTestimonialId,
                        },
                    })
                    .then(results => {
                        const sharedTestimonial = testimonialMapper(results?.data?.data);
                        setActiveTestimonial(sharedTestimonial);
                        setShowModal(true);
                    });
            }
        }
    }, [router, filteredTestimonials, testimonialMapper]);

    return (
        <>
            {headerGroup?.headerModules?.map((block, index) => (
                <HeaderModuleBlocks key={index} layoutData={block as unknown} />
            ))}

            <section
                className={cx(styles.main, {
                    [`${styles.mainNoHeader}`]: !headerGroup?.headerModules,
                })}
            >
                <Container>
                    <header className={styles.header}>
                        <div className={styles.headerCol}>
                            <RevealElement>
                                <h2 className="u-a4">
                                    <TranslationKey name="discoverAllStories" />
                                </h2>
                            </RevealElement>
                        </div>
                        <div className={cx(styles.headerCol, styles.headerColNoPadding)}>
                            <div className={styles.dropdownCol}>
                                <RevealElement>
                                    <div className={styles.dropdown}>
                                        <SelectDropdown
                                            onChange={onCountryChange}
                                            options={countries}
                                            selectedValue={query?.countryCode}
                                            disabled={countries?.length === 0}
                                            placeholder={TranslationKeyString('typeToFilter')}
                                            prefix={TranslationKeyString('country')}
                                            allOptionLabel={TranslationKeyString('all')}
                                        />
                                    </div>
                                </RevealElement>
                            </div>
                            <div className={styles.dropdownCol}>
                                <RevealElement>
                                    <div className={styles.dropdown}>
                                        <SelectDropdown
                                            onChange={onProductChange}
                                            options={products}
                                            selectedValue={query?.product}
                                            disabled={products?.length === 0}
                                            placeholder={TranslationKeyString('typeToFilter')}
                                            prefix={TranslationKeyString('product')}
                                            allOptionLabel={TranslationKeyString('all')}
                                        />
                                    </div>
                                </RevealElement>
                            </div>
                            <div className={styles.dropdownCol}>
                                <RevealElement>
                                    <div className={styles.dropdown}>
                                        <SelectDropdown
                                            onChange={onGenderChange}
                                            options={[
                                                {
                                                    value: 'male',
                                                    label: TranslationKeyString('genderMale'),
                                                },
                                                {
                                                    value: 'female',
                                                    label: TranslationKeyString('genderFemale'),
                                                },
                                            ]}
                                            selectedValue={query?.gender}
                                            placeholder={TranslationKeyString('typeToFilter')}
                                            prefix={TranslationKeyString('gender')}
                                            allOptionLabel={TranslationKeyString('all')}
                                        />
                                    </div>
                                </RevealElement>
                            </div>
                        </div>
                    </header>

                    {showModal ? (
                        <TestimonialModal
                            closeModal={closeModal}
                            testimonialId={activeTestimonial?.id}
                            title={activeTestimonial?.name ?? ''}
                            country={activeTestimonial?.country}
                            quote={activeTestimonial?.quote ?? ''}
                            product={activeTestimonial?.product ?? null}
                            imageUrl={activeTestimonial?.photos?.vertical?.sourceUrl}
                            video={activeTestimonial?.video}
                        />
                    ) : null}

                    {filteredTestimonials?.length > 0 ? (
                        <div
                            className={cx(styles.container, {
                                [styles.isLoading]: loading,
                            })}
                        >
                            <div className={styles.row}>
                                {filteredTestimonials?.map(testimonial => (
                                    <div className={styles.col} key={testimonial.id}>
                                        <RevealElement>
                                            <Testimonial
                                                title={testimonial.name}
                                                align="center"
                                                openModal={event => openModal(event, testimonial)}
                                                images={{
                                                    vertical: testimonial.photos.vertical.sourceUrl,
                                                    horizontal:
                                                        testimonial.photos.horizontal.sourceUrl,
                                                }}
                                                product={testimonial?.product || null}
                                                isVideo={Boolean(testimonial?.video)}
                                            />
                                        </RevealElement>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p className={cx(styles.emptyTitle, 'u-a3')}>
                            <TranslationKey name="noResults" />
                        </p>
                    )}

                    {totalResults > pageSize && (
                        <RevealElement>
                            <Pagination
                                disabled={loading}
                                current={query?.page || 1}
                                pageSize={pageSize}
                                total={totalResults}
                                onPageChange={pageNumber =>
                                    changeLocationParams(query, { page: pageNumber })
                                }
                            />
                        </RevealElement>
                    )}
                </Container>
            </section>

            {generalModulesGroup?.modules?.map((block, index) => (
                <GeneralModuleBlocks key={index} layoutData={block as unknown} />
            ))}
        </>
    );
};

Testimonials.defaultProps = {
    headerGroup: {},
    generalModulesGroup: {},
};

Testimonials.propTypes = {
    headerGroup: PropTypes.oneOfType([PropTypes.object]),
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
};

export default Testimonials;
